import React from "react";
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import "../index.css";

const BackHomeButton = () => {
  return <div className="BackHomeButton">
    <Link style={{textDecoration: 'none'}} to={"/portfolio"}>
        <HomeIcon  fontSize="medium"/>
    </Link>
  </div>;
};

export default BackHomeButton;
