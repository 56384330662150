import './about.css'
import Mauro from '../images/photo_M.png';
import Ricardo from '../images/photo_R.png';

const About = () => {
    return (
        <div className='aboutMain'>
            <div className='aboutUsMainDiv'>
                <div className='aboutUsCompany'>
                <h3>Bem-vindo à Critical Click!</h3>
                    <p>Fundada com o propósito de tornar o mundo digital mais amigável e intuitivo, na Critical Click acreditamos que cada pessoa e cada negócio, grande ou pequeno, têm o direito de ter uma presença online poderosa e eficaz, mas sem nunca descurar o controlo orçamental que aflige parte das empresas nos tempos modernos .</p>
                    <p>Na nossa jornada, procuramos oferecer soluções personalizadas e acessíveis que capacitam os nossos clientes a alcançarem os seus objetivos online. Desde a conceção de websites cativantes até às estratégias de marketing digital, estamos empenhados em simplificar o seu processo de entrada no mundo online e proporcionar-lhe resultados tangíveis construídos nos alicerces duma presença sólida obtida através de pequenos passos bem estruturados.</p>
                    <p>Combinando criatividade, inovação e experiência, na Critical Click estamos prontos para ajudá-lo a tomar a decisão correta no mundo digital. Junte-se a nós nesta emocionante viagem e deixe-nos ajudá-lo a alcançar o sucesso online que tanto merece!</p>
                </div>
                <div className='aboutUsBoth'>
                    <div className='aboutUsM'>
                        <div className='aboutUsImgMDiv'>
                            <img className='aboutUsImgM' src={Mauro} alt='Mauro Almeida' />
                        </div>
                        <div className='aboutUsQuoteDiv_1'>
                        <p className='aboutUsQuoteM_1'>Mauro Almeida – “Guie a sua empresa para o mundo digital”</p>
                    </div>
                        <div className='aboutUsTextR'>
                            <p>Estudioso, criativo e com uma vasta experiência na área audiovisual, assim é o Ricardo, um verdadeiro criador digital que se destaca entre os seus pares. A cada clique transforma ideias em websites de sucesso, combinando conhecimentos sólidos em design e usabilidade para criar plataformas cativantes e eficazes.<br />"Cada website é uma história pronta para ser contada", enquanto desvenda os segredos do mundo digital para encantar e converter visitantes em clientes fiéis. Com uma mente afiada e uma paixão pela inovação, Ricardo lidera o caminho na construção de websites que não só impressionam visualmente, mas também impulsionam o crescimento dos negócios. Com ele no comando, o seu barco está pronto para zarpar.</p>
                        </div>
                        <div className='aboutUsTextM_1'>
                            <p>Com mais de uma década de experiência em vendas, gestão de clientes e lançamento bem-sucedido da sua própria empresa, Mauro é um especialista em forjar estratégias digitais personalizadas e inovadoras. O seu lema? "Não seja apenas mais uma empresa, seja a empresa" Enquanto profere estas palavras vai estudando e revendo os intricados detalhes que impulsionam o sucesso no mundo digital.<br />Com olhos aguçados para oportunidades e um radar digital infalível, Mauro guia as pequenas empresas pelo labirinto do mercado online, transformando seus passos hesitantes em passos de gigante. Ele sabe tão bem como você que é hora de dar à sua empresa toda a importância que ela merece.</p>
                        </div>
                    </div>
                    <div className='aboutUsQuoteDiv'>
                        <p className='aboutUsQuoteM'>Mauro Almeida – “Guie a sua empresa para o mundo digital”</p>
                        <p className='aboutUsQuoteR'>Ricardo Silva – “A imagem do seu Website é o espelho da sua empresa”</p>
                    </div>
                    <div className='aboutUsR'>
                        <div className='aboutUsTextM'>
                            <p>Com mais de uma década de experiência em vendas, gestão de clientes e lançamento bem-sucedido da sua própria empresa, Mauro é um especialista em forjar estratégias digitais personalizadas e inovadoras. O seu lema? "Não seja apenas mais uma empresa, seja a empresa" Enquanto profere estas palavras vai estudando e revendo os intricados detalhes que impulsionam o sucesso no mundo digital.<br />Com olhos aguçados para oportunidades e um radar digital infalível, Mauro guia as pequenas empresas pelo labirinto do mercado online, transformando seus passos hesitantes em passos de gigante. Ele sabe tão bem como você que é hora de dar à sua empresa toda a importância que ela merece.</p>
                        </div>
                        <div className='aboutUsImgRDiv'>
                            <img className='aboutUsImgR' src={Ricardo} alt='Ricardo Silva' />
                        </div>
                        <div className='aboutUsQuoteDiv_1'>
                        <p className='aboutUsQuoteR_1'>Ricardo Silva – “A imagem do seu Website é o espelho da sua empresa”</p>
                    </div>
                        <div className='aboutUsTextR_1'>
                            <p>Estudioso, criativo e com uma vasta experiência na área audiovisual, assim é o Ricardo, um verdadeiro criador digital que se destaca entre os seus pares. A cada clique transforma ideias em websites de sucesso, combinando conhecimentos sólidos em design e usabilidade para criar plataformas cativantes e eficazes.<br />"Cada website é uma história pronta para ser contada", enquanto desvenda os segredos do mundo digital para encantar e converter visitantes em clientes fiéis. Com uma mente afiada e uma paixão pela inovação, Ricardo lidera o caminho na construção de websites que não só impressionam visualmente, mas também impulsionam o crescimento dos negócios. Com ele no comando, o seu barco está pronto para zarpar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;