import React from "react";
import BackHomeButton from "../../components/BackHomeButton";
import HotelNavBar from "./HotelNavBar";
import HotelFooter from "./HotelFooter";
import "./hotel.css";
import FrontViewHotel from "./images/hotel1.jpg";
import BackViewHotel from "./images/hotel2.jpg";

const HotelAboutPage = () => {
  return (
    <div className="hotelHomePage">
        <HotelNavBar />
        <div className="hotelAboutPageDiv">
          <img
            src={FrontViewHotel}
            alt="Front view of the hotel Realeza de Sistelo"
          />
          <h2>Sobre o nosso Hotel</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec erat metus, semper quis sollicitudin sit amet, accumsan sit amet diam. Sed suscipit felis id urna euismod luctus a et nulla. Vivamus leo orci, dapibus eget scelerisque sed, vehicula ac diam. Nulla ac porta sapien, eu scelerisque tellus. Sed ut mauris eu mauris feugiat congue. In hendrerit tellus quis lorem porta imperdiet. Vestibulum faucibus tempor libero at congue. Quisque non massa sollicitudin, euismod nunc at, vulputate leo. Aliquam volutpat ut justo nec convallis. Suspendisse ullamcorper odio eu felis rhoncus iaculis. Phasellus varius ut odio ut interdum. In hac habitasse platea dictumst. Sed fringilla mi et elit facilisis vehicula. Pellentesque eu fermentum nibh.</p>
          <br />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec erat metus, semper quis sollicitudin sit amet, accumsan sit amet diam. Sed suscipit felis id urna euismod luctus a et nulla. Vivamus leo orci, dapibus eget scelerisque sed, vehicula ac diam. Nulla ac porta sapien, eu scelerisque tellus. Sed ut mauris eu mauris feugiat congue. In hendrerit tellus quis lorem porta imperdiet. Vestibulum faucibus tempor libero at congue. Quisque non massa sollicitudin, euismod nunc at, vulputate leo. Aliquam volutpat ut justo nec convallis. Suspendisse ullamcorper odio eu felis rhoncus iaculis. Phasellus varius ut odio ut interdum. In hac habitasse platea dictumst. Sed fringilla mi et elit facilisis vehicula. Pellentesque eu fermentum nibh.</p>
          <br />
          <img src={BackViewHotel}  alt="Back view of the hotel Realeza de Sistelo" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec erat metus, semper quis sollicitudin sit amet, accumsan sit amet diam. Sed suscipit felis id urna euismod luctus a et nulla. Vivamus leo orci, dapibus eget scelerisque sed, vehicula ac diam. Nulla ac porta sapien, eu scelerisque tellus. Sed ut mauris eu mauris feugiat congue. In hendrerit tellus quis lorem porta imperdiet. Vestibulum faucibus tempor libero at congue. Quisque non massa sollicitudin, euismod nunc at, vulputate leo. Aliquam volutpat ut justo nec convallis. Suspendisse ullamcorper odio eu felis rhoncus iaculis. Phasellus varius ut odio ut interdum. In hac habitasse platea dictumst. Sed fringilla mi et elit facilisis vehicula. Pellentesque eu fermentum nibh.</p>
          <br />
        </div>
        <div className="Gapper"></div>
        <HotelFooter />
        <BackHomeButton />
    </div>
    );
};

export default HotelAboutPage;
