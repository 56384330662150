import './mission.css'
import MissionImage from '../images/mission.png'
import ValuesImage from '../images/values.png'

const Mission = () => {
    return (
        <div className='missionValuesMain'>
            <div className='missionValuesDiv'>
                <p className='missionValuesTitle'>A nossa Missão</p>
                <div className='missionDiv'>
                    <div className='missionTextDiv'>
                        <p>Temos como missão capacitar as empresas a alcançarem o seu pleno potencial no mundo digital, fornecendo soluções de marketing inovadoras e personalizadas. Estamos comprometidos em oferecer resultados tangíveis aos nossos clientes, ajudando-os a construir marcas sólidas, aumentar a sua presença online e impulsionar o crescimento do seu negócio. Através dum acompanhamento estruturado e responsável traçamos o objetivo de descomplicar o caminho para o seu sucesso digital, tornando-o acessível, de fácil entendimento e com resultados que se mostram certeiros a longo prazo.</p>
                    </div>
                    <div className='missionImgDiv'>
                        <img className='missionImg' src={MissionImage} alt='Abstract painting' />
                    </div>
                </div>
            </div>
            <div className='missionValuesDiv_1'>
                <p className='missionValuesTitle_1'>Os valores que representamos</p>
                <div className='valuesDiv'>
                    <div className='valuesImgDiv'>
                        <img className='valuesImg' src={ValuesImage} alt='Abstract painting' />
                    </div>
                    <div className='valuesTextDiv'>
                        <p>Vislumbramos um futuro onde todas as empresas, independentemente do seu tamanho ou setor, possam prosperar no ambiente digital. Queremos ser reconhecidos como líderes de mercado, continuamente inovando e elevando os padrões da indústria de marketing digital. A nossa visão passa por sermos o parceiro favorito dos nossos clientes, uma espécie de amigo fiel, fornecendo estratégias e serviços que geram impacto e promovem o sucesso não só imediato, mas sobretudo a longo prazo.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mission;