import { Link } from "react-router-dom";
import '../index.css';
import LogoW from './LogoLongW.png';
import ReactGA from 'react-ga';

const NavBar = () => {

  const trackNavClick = (label) => {
    ReactGA.event({
      category: 'Navigation',
      action: 'Click',
      label: label
    });
  };

  return (
    <header>
      <div className="header">
        <Link className="navLinks" to={"/"} onClick={() => trackNavClick('NavHome')}>
          <img src={LogoW} alt="Company logo" />
        </Link>
        <nav>
          <div>
          <Link className="navLinks" to={"/"} onClick={() => trackNavClick('NavHome')}>Home</Link>
          <Link className="navLinks" to={"/about"} onClick={() => trackNavClick('NavAbout')}>Sobre Nós</Link>
          <Link className="navLinks" to={"/mission"} onClick={() => trackNavClick('NavMission')}>Missão e Visão</Link>
          {/*<Link className="navLinks" to={"/portfolio"} onClick={() => trackNavClick('NavPortfolio')}>Portfólio</Link>  */}
          <Link className="navLinks" to={"/contacts"} onClick={() => trackNavClick('NavContacts')}>Contactos</Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NavBar;
