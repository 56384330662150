import React from 'react';
import ContactForm from '../../components/ContactForm'
import './contacts.css'
import mapImg from '../images/map_view.png'
import contactImg from '../images/contactsImg.png';
import ReactGA from 'react-ga';

const Contacts = () => {
  const trackContactsClick = (label) => {
    ReactGA.event({
      category: 'ContactPage',
      action: 'Click',
      label: label
    });
  };
    return (
        <div className='ContactsMain'>
            <h3>Pedidos de informação e orçamentos</h3>
            <ContactForm />
            <div className='addressContactsMap'>
              <div className='contactsDiv'>
                <h2>Contactos</h2>
                <p>Para orçamentos por favor utilize o formulário acima</p>
                <p>Contacto móvel: (+351) 916 271 584 <i>(custo de uma chamada móvel nacional)</i></p>
              </div>
              <div className='addressDiv'>
                <h2>Morada</h2>
                <p>Rua do FEIRAPARK, n.º 50, 2.3,<br />4520 – 632 S. João de Ver</p>
              </div>
              <div className='imgMap'><a href='https://maps.app.goo.gl/VXtDN1KrhnTZ5B6D9' target='_blank' rel='noreferrer'><img src={mapImg} alt='Google Maps da localização do escritório Critical Click' onClick={() => trackContactsClick('Directions')} /></a></div>
            </div>
            <div className='contactImgDiv'>
              <img className='contactImg' src={contactImg} alt='Pintura digital abstrata' />
            </div>
        </div>
    );
};

export default Contacts;