import React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  IconButton,
} from "@mui/material";
// eslint-disable-next-line
import { Facebook, Twitter, Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "../index.css";
import ReactGA from 'react-ga';

const Footer = () => {
  const trackFooterClick = (label) => {
    ReactGA.event({
      category: 'Footer',
      action: 'Click',
      label: label
    });
  };
  return (
    <div className="footerMain">
      <Grid sx={{flexGrow: 1}} container>
        {/* Our Company Section */}
        <Grid item xs sm={6} md={4}>
          <Typography variant="h6">Site</Typography>
          <List>
            <ListItem>
              <Link to="/about" onClick={() => trackFooterClick('FooterAbout')}>Sobre Nós</Link>
            </ListItem>
            <ListItem>
              <Link to="/mission" onClick={() => trackFooterClick('FooterMission')}>Missão e Valores</Link>
            </ListItem>
            <ListItem>
              <Link to="/contacts" onClick={() => trackFooterClick('FooterContacts')}>Contactos</Link>
            </ListItem>
          </List>
        </Grid>

        {/* Legal Section */}
        <Grid item xs sm={6} md={4}>
          <Typography variant="h6">Notas Legais</Typography>
          <List>
            <ListItem>
              <Link to="/politicas-de-privacidade" onClick={() => trackFooterClick('FooterPoliticas')}>Políticas de Privacidade</Link>
            </ListItem>
            {/* <ListItem>
              <Link to="/privacy">Politica de Privacidade</Link>
            </ListItem> */}
          </List>
        </Grid>

        {/* Portfolio Section */}
        {/* <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Páginas do nosso Portfólio</Typography>
          <List>
            <ListItem>
              <Link to="/portfolio/store">Pop Culture Warehouse</Link>
            </ListItem>
            <ListItem>
              <Link to="/portfolio/hotel">Realeza de Sistelo</Link>
            </ListItem>
            <ListItem>
              <Link to="/portfolio/restaurante">Cass' Kitchen</Link>
            </ListItem>
            <ListItem>
              <Link to="/portfolio/factory">Basic Cork</Link>
            </ListItem>
            <ListItem>
              <Link to="/portfolio/blog">Emma Vita Wedding Planner</Link>
            </ListItem>
            <ListItem>
              <Link to="/portfolio/influencer_streamer">Up Your Arsenal</Link>
            </ListItem>
          </List>
        </Grid> */}

        {/* Social Media Section */}
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h6">Redes Sociais</Typography>
          <div>
            <IconButton href="https://www.facebook.com/profile.php?id=61555755097744" target="_blank" aria-label="facebook" style={{ color: '#247d7f' }}  onClick={() => trackFooterClick('FooterFacebook')}>
              <Facebook />
            </IconButton>
            <IconButton href="https://www.instagram.com/critical.click.solutions/" target="_blank" aria-label="instagram" style={{ color: '#247d7f' }}  onClick={() => trackFooterClick('FooterInstagram')}>
              <Instagram />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <div className="footer-bottom-line">
        © 2024 Critical Click. All rights reserved.
      </div>
    </div>
  );
};

export default Footer;