import React from "react";
import { Link } from "react-router-dom";
import hotelLogo from "./hotel_logo.png";
import './hotel.css';

const HotelNavBar = () => {

  return (
    <header>
      <div className="hotelHeader">
        <div className="hotelHeaderLogoSlogan">
          <Link className="hotelNavLinks" to={"/portfolio/hotel"}>
            <img className="hotelLogo" src={hotelLogo} alt="Logo of the hotel"/>
          </Link>
          <h3 className="hotelLogoName">Onde a natureza espera por si</h3>
        </div>
        <nav>
          <div className="hotelNavLinksDiv">
          <Link className="hotelNavLinks" to={"/portfolio/hotel"}>Home</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/about"}>O nosso Hotel</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/suites"}>Suites</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/activities"}>Actividades</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/restaurant"}>Gastronomia</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/location"}>Localização</Link>
          <Link className="hotelNavLinks" to={"/portfolio/hotel/contacts"}>Contactos</Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default HotelNavBar;
