import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

import ReactGA from 'react-ga';

const CookieConsent = () => {
  const [open, setOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const trackCookieClick = (label) => {
    ReactGA.event({
      category: 'CookiesDialog',
      action: 'Click',
      label: label
    });
  };

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) setOpen(true);
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'all');
    initializeAnalytics(); // Initialize Google Analytics
  };

  const initializeAnalytics = () => {
    ReactGA.initialize('G-YKS3D8XR8G');
    ReactGA.pageview(window.location.pathname + window.location.search);
    setInitialized(true);
    setOpen(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'none');
    clearAnalytics();
    setOpen(false);
  };

  const clearAnalytics = () => {
    document.cookie = '_ga; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  const handleEssential = () => {
    localStorage.setItem('cookieConsent', 'essential');
    setOpen(false);
    // Initialize only essential services here
  };

  return (
    <div className={open ? 'divCookiesMain' : 'divCookiesMain hidden'}>
      <div className='divCookie'>
        <div className='divCookieText'>
          <h3>Consentimento Cookies</h3>
          <p>Este site utiliza cookies para garantir a melhor experiência de navegação. Ao continuar a usar o site, está a concordar com a nossa <Link className='termsOfServiceLink' to="/politicas-de-privacidade"  onClick={() => trackCookieClick('CookieDialogPoliticas')}>política</Link> utilização de cookies.</p>
        </div>
        <div className='divCookieBtns'>
          <Button variant='contained' color='secondary' onClick={handleReject}>Rejeitar Tudo</Button>
          <Button variant='outlined' color='primary' onClick={handleEssential}>Apenas Essenciais</Button>
          <Button variant='contained' color='success' onClick={handleAccept}>Aceitar Tudo</Button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
