import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './index.css';
import './fonts/fontStyles.css'
import ScrollToTop from './components/ScrollToTop'
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Mission from "./pages/Mission/Mission";
import Portfolio from "./pages/Portfolio/Portfolio";
import Contacts from "./pages/Contacts/Contacts";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

import CookieConsent from "./components/CookieConsent";



import Layout from "./components/Layout";

// PORTFOLIO PAGES

import HotelMainPage from "./portfolioPages/hotelPages/HotelMainPage";
import HotelAboutPage from "./portfolioPages/hotelPages/HotelAbout";
import HotelActivitiesPage from "./portfolioPages/hotelPages/HotelActivities";
import HotelContactsPage from "./portfolioPages/hotelPages/HotelContacts";
import HotelLocationPage from "./portfolioPages/hotelPages/HotelLocation";
import HotelRestaurantPage from "./portfolioPages/hotelPages/HotelRestaurant";
import HotelSuitesPage from "./portfolioPages/hotelPages/HotelSuites";



function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <HashRouter>
        <ScrollToTop />
        <CookieConsent />
          <Routes>
          <Route path="/" element={<Layout />}>
              <Route
                path="/"
                element={<Home />}
              ></Route>
              <Route
                path="/about"
                element={<About />}
              ></Route>
              <Route
                path="/mission"
                element={<Mission />}
              ></Route>
              <Route
                path="/portfolio"
                element={<Portfolio />}
              ></Route>
              <Route
                path="/contacts"
                element={<Contacts />}
              ></Route>
              <Route
                path="/politicas-de-privacidade"
                element={<PrivacyPolicy />}
              ></Route>
            </Route>
            {/* Portfolio specific routes */}
            <Route path="/portfolio/*" element={<Layout />}>
              <Route path="hotel" element={<HotelMainPage />} />
              <Route path="hotel/about" element={<HotelAboutPage />} />
              <Route path="hotel/suites" element={<HotelSuitesPage />} />
              <Route path="hotel/activities" element={<HotelActivitiesPage />} />
              <Route path="hotel/restaurant" element={<HotelRestaurantPage />} />
              <Route path="hotel/location" element={<HotelLocationPage />} />
              <Route path="hotel/contacts" element={<HotelContactsPage />} />
            </Route>
            
          </Routes>
        </HashRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
