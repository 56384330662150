import React from "react";
import BackHomeButton from "../../components/BackHomeButton";
import HotelNavBar from "./HotelNavBar";
import HotelFooter from "./HotelFooter";
import "./hotel.css";

const HotelRestaurantPage = () => {
  return (
    <div className="hotelHomePage">
        <HotelNavBar />
        <h3>HOTEL RESTAURANT PAGE</h3>
        <HotelFooter />
        <BackHomeButton />
    </div>
    );
};

export default HotelRestaurantPage;
