import React from "react";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <div className="portfolioMain">
      <div className="portfolioTopDiv">
        <h3>PORTFOLIO</h3>
        <p>Nesta secção encontrará alguns exemplos de websites, e um exemplo de uma loja online, que são totalmente fictícios e apenas para fins demonstrativos. Qualquer um dos exemplos abaixo não têm qualquer tipo de affiliação a uma empresa real e caso exista semalhanças ou parecenças com um local / negócio real, é apenas coincidência.</p>
        <p>Ao clicar num dos exemplos abaixo, será levado a uma parte especial do nosso website que parecerá uma página verdadeira do exemplo que escolheu. No entanto harevá um botão no canto inferior esquerdo que poderá clicar para depois voltar a esta página. Veja as desctições de cada exemplo do que se trata e o que pode fazer em cada uma delas.</p>
      </div>
      <Grid container spacing={12} sx={{marginTop: 2, marginBottom: 20}}>
      <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/store"}>
            <div className="portfolioBtnStore"></div>
            <div className="portfolioBtnName">Loja</div>
            <div className="portfolioBtnText">
              Uma página de uma Loja Online
            </div>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/hotel"}>
            <div className="portfolioBtnHotel"></div>
            <div className="portfolioBtnName">Realeza de Sistelo</div>
            <div className="portfolioBtnText">Website de um hotel na aldeia de Sistelo, em Arcos de Valdevez.</div>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/restaurant"}>
            <div className="portfolioBtnRestaurant"></div>
            <div className="portfolioBtnName">Restaurante</div>
            <div className="portfolioBtnText">Uma página de um Restaurante</div>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/factory"}>
            <div className="portfolioBtnFactory"></div>
            <div className="portfolioBtnName">Fábrica</div>
            <div className="portfolioBtnText">Uma página de uma Fábrica</div>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/blog"}>
            <div className="portfolioBtnBlog"></div>
            <div className="portfolioBtnName">Blog</div>
            <div className="portfolioBtnText">Uma página de um Blog</div>
          </Link>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Link className="portfolioLinks" to={"/portfolio/influencer_streamer"}>
            <div className="portfolioBtnInfluStream"></div>
            <div className="portfolioBtnName">Influencer / Streamer</div>
            <div className="portfolioBtnText">
              Uma página de Influencer / Streamer
            </div>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default Portfolio;
