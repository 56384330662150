import './home.css'
import SloganImage from '../images/slogan.png'
import WWDDM from '../images/digital_marketing.png'
import WWDWC from '../images/website_onlinestore_creation.png';
import ReactGA from 'react-ga';

import { Link } from "react-router-dom";

const Home = () => {
    const consent = localStorage.getItem('cookieConsent');
    if(consent === 'none') {
      document.cookie = '_ga=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = '_ga_YKS3D8XR8G=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = '_gid=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    const trackHomeClick = (label) => {
        ReactGA.event({
          category: 'Home',
          action: 'Click',
          label: label
        });
      };
    return (
        <div className='pagesMain'>
            <div className='slogan'>
                <img src={SloganImage} alt='Abstract illustration of a city' />
            </div>
            <div className='honestOpinion'>
                <div className='opinion1'>
                    <h3>Porquê ter um negócio online?</h3>
                    <p>Optar por um negócio online é uma escolha estratégica que traz várias vantagens. Com custos iniciais mais acessíveis e a capacidade de alcançar clientes em todo o mundo, tens a liberdade de construir o teu próprio caminho empresarial de forma flexível. A análise de dados fornece informações valiosas, permitindo que tomes decisões informadas para impulsionares o crescimento do teu negócio.</p> 
                </div>
                <div className='opinion2'>
                    <h3>Porquê as redes sociais?</h3>
                    <p>As redes sociais são essenciais para qualquer empresa devido à sua capacidade de proporcionar alcance global, interação direta com os clientes, aumento da visibilidade da marca e feedback instantâneo dos consumidores. Além disso, são uma plataforma eficaz para compartilhar conteúdo relevante e construir relacionamentos duradouros com o público-alvo.</p> 
                </div>
                <div className='opinion3'>
                    <h3>Porquê a SEO da Google?</h3>
                    <p>Investir em SEO do Google é crucial para garantir a visibilidade online da sua empresa. Com uma boa estratégia de SEO, o seu site tem mais probabilidade de aparecer nos resultados de pesquisa relevantes, aumentando a sua exposição e atraindo tráfego qualificado. Isso não só aumenta as hipóteses de conversões, mas também estabelece a sua marca como uma autoridade no setor, construindo confiança com os consumidores e gerando crescimento sustentável a longo prazo.</p> 
                </div>                 
            </div>
            <div className='subscribeNewsletterCTA'>
                <h3>Deseja obter mais informações e sugestões para otimizar o desempenho do seu negócio? Inscreva-se na nossa newsletter, clicando no botão <i>Subscrever</i></h3>
                <div className='subscribeNewsletterCTABtnDiv'>
                    <Link to={"/contacts"} ><button className='subscribeBtnHome' onClick={() => trackHomeClick('HomeSubscribe')}>Subscrever</button></Link>
                </div>
            </div>
            <div className='whatWeDo'>
                <h3>Os Serviços que disponibilizamos</h3>
                <div className='whatWeDoMainDiv'>
                    <div className='weDoWebsiteCreation'>
                        <div className='innerWWD'>
                            <h3>Website e Lojas Online</h3>
                            <p>Numa ótica de redução de custos para empresas pequenas ou a iniciar a sua actividade especializamo-nos na criação de websites e lojas online em plataformas closed source, como Shopify, Wix, entre outras. Desenvolvemos soluções personalizadas, adaptadas às necessidades específicas de cada cliente, desde a conceção do design até à implementação das funcionalidades necessárias.</p>
                        </div>
                        <img src={WWDWC} alt='website and online store creation' />
                    </div>
                    <div className='weDoTraffic'>
                        <div className='innerWWD'>
                            <h3>Gestão de tráfego pago</h3>
                            <p>A gestão de tráfego pago envolve a utilização estratégica e profissional de plataformas de anúncios como Google Ads, Anúncios do Facebook e Instagram, entre outras. Segmentamos, localizamos e aperfeiçoamos os seus anúncios de forma a atingirem todos os canais digitais relevantes. O segredo reside em atrair visitantes qualificados, com interesse em adquirir os seus produtos ou serviços, direcionando-os para os seus sites, páginas de destino com formulários de contacto ou simplesmente para aumentar o reconhecimento da sua marca no mundo online.</p>
                        </div>
                    </div>
                    <div className='weDoDigitalMark'>
                    <div className='innerWWD'>
                            <h3>Redes sociais e SEO</h3>
                            <p>Oferecemos serviços de gestão de redes sociais, criando conteúdo relevante e cativante para aumentar o envolvimento do público-alvo. Além disso, implementamos estratégias de SEO (Search Engine Optimization) para melhorar o ranking nos motores de busca e aumentar a visibilidade online dos nossos clientes.</p>
                        </div>
                        <img src={WWDDM} alt='digital marketing' />
                    </div>
                </div>
            </div>
            <div className='infoBudgetContact'>
                <h3>Quer obter informações ou orçamentos dos nossos serviços?</h3>
                <Link to={"/contacts"} ><button className='infoBudgetContactBtn' onClick={() => trackHomeClick('HomeContactUs')}>Contacte-nos</button></Link>
            </div>
        </div>
    )
}

export default Home;