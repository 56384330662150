import React from "react";
import BackHomeButton from "../../components/BackHomeButton";
import HotelNavBar from "./HotelNavBar";
import HotelFooter from "./HotelFooter";
import "./hotel.css";
import Carousel from "react-material-ui-carousel";
import Image1 from "./images/outdoor.jpg";
import Image2 from "./images/ballroom.jpg";
import Image3 from "./images/restaurant.jpg";
import Image4 from "./images/room.jpg";
import Image5 from "./images/model_room.jpg";
import Image6 from "./images/stairs.jpg";
import Image7 from "./images/towel.jpg";
import LongLogo from "./Logo_long.png";
import Suite from "./images/suite.jpg";
import Vineyard from "./images/vineyard.jpg";

import { Card, CardMedia, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const HotelMainPage = () => {
  const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7];
  return (
    <div className="hotelHomePage">
      <HotelNavBar />
      <div style={{ backgroundColor: "white", margin: 0, padding: 0 }}>
        <Carousel
          autoPlay
          interval={3000}
          navButtonsAlwaysInvisible={true}
          indicators={false}
          animation="fade"
          width="100%"
        >
          {images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt={"Slide"}
              style={{ width: "100%", height: "720px", objectFit: "cover" }}
            />
          ))}
        </Carousel>
      </div>
      <div style={{ marginTop: "250px", display: "flex" }}>
        <div
          style={{ width: "25%", backgroundColor: "white", height: "120px" }}
        ></div>
        <img
          src={LongLogo}
          alt="Realeza de Sistelo"
          style={{ width: "50%", maxHeight: "120px" }}
        />
        <div
          style={{ width: "25%", backgroundColor: "white", height: "120px" }}
        ></div>
      </div>
      <div className="hotelInfoDiv">
        <h2 className="sloganHalfPoint">
          Aldeia de Sistelo - O Tibet de Portugal
        </h2>
        <div
          style={{
            height: 2,
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ height: 1, width: "35%" }}></div>
          <div
            style={{ height: 1, width: "30%", backgroundColor: "black" }}
          ></div>
          <div style={{ height: 1, width: "35%" }}></div>
        </div>
        <div className="hotelInfoTextMain">
          <span>
            Onde a natureza vem para descansar, a aldeia de Sistelo é onde pode
            relaxar e tirar as férias que precisa
          </span>
        </div>
        <div className="hotelInfoTextParagraph">
          <span>
            No nosso hotel poderá viver um pouco o que é estar verdadeiramente
            descançado e onde pode retirar-se do mundo urbano,
          </span>
          <span>
            em que pode tirar as férias merecidas longe da confusão da cidade!
          </span>
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>
            Não lhe faltará nada ao fazer parte da Realeza de Sistelo!
          </span>
        </div>
      </div>
      <div className="hotelActivitiesLocationTransparent">
        <h2 className="hotelActivitiesLocationTransparentTitle">
          Sinta-se como REALEZA
        </h2>
        <span className="hotelActivitiesLocationTransparentText">
          O nosso hotel consiste em 3 suites, 4 quartos, uma quinta e ainda
          derivadas atividades para se sentir especial.
        </span>
        <br />
        <br />
        <span className="hotelActivitiesLocationTransparentText">
          Uma das nossas atividades especials são as provas de vinhos da região,
          onde pode fazer parte do processo de provar a qualidade do vinho
          produzido nas nossas vinhas!
        </span>
      </div>
      <div className="hotelRoomsServicesInfo">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "20px",
          }}
        >
          <Card style={{ width: "48%" }}>
            <Link
              to="/portfolio/hotel/suites"
              style={{ textDecoration: "none" }}
            >
              <CardMedia
                component="img"
                height="75%"
                image={Suite}
                alt="Hotel Rooms"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Suites e Quartos
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Aqui encontra informações sobre as nossas suites e quartos.
                </Typography>
              </CardContent>
              <div className="cardCTA">VER QUARTOS</div>
            </Link>
          </Card>
          <Card style={{ width: "48%" }}>
            <Link
              to="/portfolio/hotel/activities"
              style={{ textDecoration: "none" }}
            >
              <CardMedia
                component="img"
                height="75%"
                image={Vineyard}
                alt="Services"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Atividades do Hotel
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Nesta página encontrará as informações sobre o que fazer na
                  Realeza de Sistelo
                </Typography>
              </CardContent>
              <div className="cardCTA">VER ATIVIDADES</div>
            </Link>
          </Card>
        </div>
      </div>
      <h1
        style={{
          fontFamily:
            "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
          fontWeight: "bold",
          color: "#001A1A",
          backgroundColor: "white",
          padding: "20px",
          margin: 0,
          textAlign: "center",
        }}
      >
        SERVIÇOS
      </h1>
      <div className="hotelRoomsServicesInfoList">
        <ul>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
        </ul>
        <ul>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
        </ul>
        <ul>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
          <li>test</li>
        </ul>
      </div>
      <HotelFooter />
      <BackHomeButton />
    </div>
  );
};

export default HotelMainPage;
