import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Box, FormControlLabel, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";
import '../index.css';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';




const ContactForm = () => {
  const pixelID = '792858709494053';
  useEffect(() => {
    ReactPixel.init(pixelID);
  }, []);

  const trackContactFormClick = (label) => {
    ReactGA.event({
      category: 'ContactForm',
      action: 'Click',
      label: label
    });
  };

  const recaptchaRef = useRef(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // eslint-disable-next-line
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [emailValid, setEmailValid] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedNL, setIsCheckedNL] = useState(true);
  const [newsletterText, setNewsletterText] = useState(isCheckedNL ? "\nQUERO SUBSCREVER À VOSSA NEWSLETTER" : "");


  const onRecaptchaChange = (value) => {
    // Here you can handle the verification of the reCAPTCHA
    console.log("Captcha value:", value);
  };

  const validateEmail = (email) => {
    // eslint-disable-next-line 
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckboxNLChange = (event) => {
    setIsCheckedNL(event.target.checked);
    const newText = event.target.checked ? "\nQUERO SUBSCREVER À VOSSA NEWSLETTER" : "";
    setNewsletterText(newText);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    ReactPixel.track('Lead', {
      content_name: 'Contact Form',
    });

    const name = e.target.from_name.value;
    const originalMessage = e.target.message.value;
    const finalMessage = originalMessage + newsletterText;

    e.target.message.value = finalMessage;

    if (!name.trim() || !finalMessage.trim()) {
      setSnackbarMessage('Por favor, preencha todos os campos obrigatórios.');
      setOpenSnackbar(true);
      setIsSubmitting(false);
      return;
    }

    const email = e.target.user_email.value; // Retrieve the email value from the form
    if (!validateEmail(email)) {
      setSnackbarMessage('Por favor, forneça um email válido.');
      setOpenSnackbar(true);
      setIsSubmitting(false);
      setEmailValid(false); // Set email validation state
      return;
    }

    if (!isChecked) {
      setSnackbarMessage('Por favor, aceite os termos antes de enviar.');
      setOpenSnackbar(true);
      return;
    }

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setSnackbarMessage('Por favor confirme que não é um bot');
      setOpenSnackbar(true);
      return;
    }

    emailjs.sendForm('service_ep4pr3h', 'template_2hv34np', e.target, 'mZix4BcKoyZaYXdCt')
      .then((result) => {
        setSnackbarMessage('Mensagem enviada com successo!');
        setOpenSnackbar(true);
        e.target.message.value = originalMessage;
        e.target.reset();
        setTimeout(() => setIsSubmitting(false), 3000);
        setIsSubmitted(true);
      }, (error) => {
        setSnackbarMessage('Falha no envio da mensagem, por favor tente mais tarde.');
        setOpenSnackbar(true);
        e.target.message.value = originalMessage;
        setTimeout(() => setIsSubmitting(false), 3000);
      });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  

  return (
    <Box
      component="form"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& .MuiTextField-root': { m: 1, width: '35ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={sendEmail}
    >
      {isSubmitted ? (
      <div className='afterMessage'>
        <h2>Obrigado pela sua mensagem!</h2>
      </div>
    ) : (
      <div className='mainContactFormDiv'>
        <div className='contactsTextExplain'>
          <p>Deseja receber um orçamento para os nossos serviços de marketing digital, criação de websites ou lojas online? Por favor, deixe sua mensagem abaixo para entrar em contato connosco e responderemos o mais breve possível!</p>
        </div>
        <TextField 
            name="from_name"
            required
            color='primary'
            label="Nome"
            sx={{
                backgroundColor: 'white',
                borderRadius: '4px',
                '& label.Mui-focused': {
                  color: '#247d7f',
                  transform: 'translate(14px, -16px) scale(0.75)',
                  borderRadius: '4px',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'white',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
        />
        <TextField
            name="user_email"
            required
            type='email'
            label="Email"
            error={!emailValid}
            helperText={!emailValid && "Email inválido."}
            sx={{
                backgroundColor: 'white',
                borderRadius: '4px',
                '& label.Mui-focused': {
                  color: '#247d7f',
                  transform: 'translate(14px, -16px) scale(0.75)',
                  borderRadius: '4px',
                },
                '& .MuiInput-underline:after': {
                  borderBottomColor: 'white',
                },
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                  },
                },
              }}
        />
        <TextField
          name="message" 
          label="Mensagem"
          multiline
          rows={4}
          defaultValue=""
          sx={{
            backgroundColor: 'white',
            borderRadius: '4px',
            '& label.Mui-focused': {
              color: '#247d7f',
              transform: 'translate(14px, -16px) scale(0.75)',
              borderRadius: '4px',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'white',
            },
            '& .MuiOutlinedInput-root': {
              '&.Mui-focused fieldset': {
                borderColor: 'transparent',
              },
            },
          }}
        />
        <FormControlLabel
          control={
          <Checkbox
            checked={isChecked}
            onChange={handleCheckboxChange}
            name="gdprCheck"
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: '#247d7f', 
              },
              '&.Mui-focusVisible': {
                outlineColor: 'blue',
              }
            }}
          />
          }
          label={
            <span className='termsOfServiceLabel'>
              Eu concordo com as <Link className='termsOfServiceLink' to="/politicas-de-privacidade"  onClick={() => trackContactFormClick('ContactFormPoliticas')}>Políticas de Privacidade</Link>.
            </span>
          }
        />
        <FormControlLabel
          control={
          <Checkbox
            checked={isCheckedNL}
            onChange={handleCheckboxNLChange}
            name="newsletterCheck"
            sx={{
              color: 'gray',
              '&.Mui-checked': {
                color: '#247d7f', 
              },
              '&.Mui-focusVisible': {
                outlineColor: 'blue',
              }
            }}
          />
          }
          label={
            <span className='termsOfServiceLabel'>
              Quero fazer parte da vossa newsletter.
            </span>
          }
        />
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Lc6UF0pAAAAAEy4QBwjliW9fR77gfraZMh0xO7d"
        onChange={onRecaptchaChange}
      />
      <Button 
        type="submit"
        variant="contained"
        color='primary'
        sx={{
            maxWidth: '50%',
            alignSelf: 'center',
            marginTop: '10px'
        }}
        onClick={() => trackContactFormClick('ContactFormSubmit')}
        >
        Submeter
      </Button>
      </div>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    
    </Box>
  );
};

export default ContactForm;
