import React from "react";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { Facebook, Twitter, Instagram, LinkedIn } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import "./hotel.css";

const HotelFooter = () => {
  return (
    <div className="hotelFooter">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Hotel</Typography>
          <ul>
            <li>Sobre Nós</li>
            <li>Instalações</li>
            <li>Politicas de Privacidade</li>
            <li>Termos e Serviços</li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Serviços</Typography>
          <ul>
          <li>Actividades</li>
          <li>Gastronomia</li>
          <li>Prova de Vinhos</li>
          <li>Vinho Regional</li>
          <li>Piscinas</li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Contactos</Typography>
          <ul>
          <li>geral@realezasistelo.pt</li>
          <li>(+351) 9X XXX XX XX</li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Typography variant="h6">Siga-nos</Typography>
          <div>
            <IconButton aria-label="facebook">
              <Facebook />
            </IconButton>
            <IconButton aria-label="twitter">
              <Twitter />
            </IconButton>
            <IconButton aria-label="instagram">
              <Instagram />
            </IconButton>
            <IconButton aria-label="linkedin">
              <LinkedIn />
            </IconButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default HotelFooter;
