import React from 'react';
import './privacypolicy.css';
import ReactGA from 'react-ga';

const Terms = () => {
    const trackPrivacyPolicyClick = (label) => {
        ReactGA.event({
          category: 'PrivacyPolicy',
          action: 'Click',
          label: label
        });
      };
    return (
    <div className='privpolMain'>
        <h1>POLÍTICAS DE PRIVACIDADE</h1>
        <i>Estas políticas de privacidade referem-se ao site em que se encontra - www.criticalclicksolutions.com</i>
        <div className='privpolTextDiv' >
            <h2>SEÇÃO 1 – TRATAMENTO DOS SEUS DADOS</h2>
            <p>
                Quando efetua o preenchimento dum formulário no nosso website recolhemos dados pessoais, como o seu nome e endereço de e-mail, como parte do processo de pedido de informações dos nossos serviços. Além disso, ao navegar na nossa página, recebemos automaticamente o protocolo de internet do seu computador e o endereço IP, para obter informações que nos ajudem a compreender mais sobre o seu navegador (Chrome, Edge, Safari ou outro) e sobre o seu sistema operativo.
            </p>
            <p>
                Email marketing: Com o seu consentimento, poderemos enviar-lhe e-mails sobre a nossa empresa e as suas associadas, novos produtos, notícias e outras atualizações. Pode remover o seu endereço de e-mail desta base de dados a qualquer momento.
            </p>

            <h2>SEÇÃO 2 – CONSENTIMENTO</h2>
            <b>
                Como obtemos o seu consentimento? 
            </b>
            <p>
                Quando nos fornece as suas informações pessoais nos questionar acerca da validade dos nossos serviços, entendemos que concorda com a nossa recolha de dados e com o seu uso, apenas e só para esse fim específico. Se pedirmos as suas informações pessoais por alguma outra razão, como por exemplo para email marketing, iremos solicitar diretamente o seu consentimento, ou em alternativa, fornecer-lhe a oportunidade de recusar.
            </p>
            <b>
                Como posso remover o meu consentimento?
            </b>
            <p>
                Se, após fornecer os seus dados, mudar de ideias, pode remover o seu consentimento para a recolha de dados contínua, uso ou divulgação dos seus dados. Pode entrar em contato connosco a qualquer momento através do e-mail geral@criticalclicksolutions.com ou por escrito para: Critical Click Solutions, Rua do FEIRAPARK, nº50, 2.3, 4520-632 São João de Ver, Portugal.
            </p>

            <h2>SEÇÃO 3 – DIVULGAÇÃO</h2>
            <p>
                Apenas divulgaremos os seus dados pessoais se formos obrigados por lei ou se violar os nossos Termos de Serviço.
            </p>

            <h2>SEÇÃO 4 – SERVIÇOS DE TERCEIROS</h2>
            <p>
                Geralmente, os fornecedores terceirizados (Facebook, Google ou outros) que utilizamos apenas recolherão, usarão e divulgarão os seus dados na medida necessária para permitir a concretização dos serviços que nos fornecem localizado nos Estados Unidos, então os seus dados pessoais usados para completar aquela transação podem estar sujeitos a divulgação sob a legislação dos Estados Unidos, incluindo a relativa ao Ato Patriota. Assim que deixar o website da nossa empresa ou de algum associado gerido por nós, ou for redirecionado para um aplicativo ou site de terceiros, deixa de estar abrangido por esta Política de Privacidade ou pelos Termos de Serviço do nosso site.
            </p>
            <p>
                Um dos parceiros utilizados neste formulário é da empresa <a className='termsOfServiceLink' href='https://www.emailjs.com' target='blank'  onClick={() => trackPrivacyPolicyClick('EmailJS-Site')}>EmailJS</a>, onde poderá consultar o seu certificado de utilização de dados dentro da União Europeia <a className='termsOfServiceLink' href='https://www.emailjs.com/legal/data-protection-agreement/' target='blank'onClick={() => trackPrivacyPolicyClick('EmailJS-GDPR')}>aqui</a>. <i>Atenção que o texto encontra-se apenas em inglês</i>
            </p>
            <b>
                Links
            </b>
            <p>
                Quando clica em algum dos links da nossa página ou de algum associado gerido por nós, pode ser direcionado para fora do nosso site. Não somos responsáveis pelas práticas de privacidade de outros sites e incentivamo-lo a ler as declarações de privacidade desses sites.
            </p>

            <h2>SEÇÃO 5 – SEGURANÇA</h2>
            <p>
                Para proteger os seus dados pessoais, tomamos precauções razoáveis e seguimos as melhores práticas da indústria para nos certificar que os mesmos não serão perdidos inadequadamente, usurpados, acedidos, divulgados, alterados ou destruídos.
            </p>
            <b>
                OS NOSSOS COOKIES
            </b>
            <p>
                Utilizamos diversos tipos de cookies que nos permitem armazenar dados sobre a sua sessão, última visita, número de visitas que já efetuou ao nosso site e outros dados, que tem como único intuito providenciar-lhe uma experiência mais fácil, segura, rápida e intuitiva.
            </p>

            <h2>SEÇÃO 6 – IDADE DE CONSENTIMENTO</h2>
            <p>
                Ao usar este site ou algum dos sites diretamente geridos por nós, afirma que atingiu pelo menos a idade legal no país ou estado onde reside e que nos deu consentimento para permitir que qualquer um dos seus dependentes menores de idade usem esse site.
            </p>

            <h2>SEÇÃO 7 – ALTERAÇÕES À POLÍTICA DE PRIVACIDADE</h2>
            <p>
                Reservamos o direito de modificar a presente política de privacidade a qualquer momento. Por favor, reveja-a com frequência. Quaisquer alterações e esclarecimentos vão ter efeito imediatamente após a sua publicação no site. Se fizermos alterações materiais a esta política, iremos notificá-lo/a sobre essa atualização, para que tenha conhecimento sobre quais os dados que recolhemos, como os utilizamos e sob que circunstâncias, se alguma, usamos e/ou os divulgamos. Se a nossa loja online for adquirida ou fundida com uma outra empresa, os seus dados podem ser transferidos para os novos proprietários para que possamos continuar a vender os nossos produtos e serviços.
            </p>

            <h2>PERGUNTAS E DADOS DE CONTACTO</h2>
            <p>
                Se quiser aceder, corrigir, alterar ou excluir quaisquer informações pessoais que temos sobre si, fazer uma queixa, ou simplesmente pedir mais informações ao nosso responsável pela Política de Privacidade, envie por favor uma mensagem por email para geral@criticalclicksolutions.com ou por correio para: Critical Click Solutions, Rua do FEIRAPARK, nº50, 2.3, 4520-632 São João de Ver, Portugal.
            </p>
        </div>
    </div>
)};

export default Terms;