import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();

  const showNavAndFooter = () => {
    return !location.pathname.startsWith('/portfolio/');
  };

  return (
    <>
      {showNavAndFooter() && <NavBar />}
      <Outlet />
      {showNavAndFooter() && <Footer />}
    </>
  );
};

export default Layout;